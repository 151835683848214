import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Content from '../components/Content/Content'
import Breadcrumb from '../components/organisms/breadcrumb/Breadcrumb'
import PageMeta from '../components/PageMeta/PageMeta'
import { type IPageQueryData } from '../graphql/queries/PageQuery'
import { graphql } from 'gatsby'

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id, idType: ID) {
        title
        content
        seo {
          title
          metaDesc
        }
        uri
        parent {
          node {
            uri
          }
        }
      }
      generalSettings {
        title
        siteId
        egSettingTwitter
      }
    }
  }
`

interface PageProps {
  data: IPageQueryData
}

const PageTemplate = ({
  data: {
    wpgraphql: { page, generalSettings }
  }
}: PageProps) => {
  const { siteId, title } = generalSettings

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: page.title,
          description: page.seo?.metaDesc,
          url: page.uri
        }}
      />
      <Header siteId={siteId} siteName={title} />
      <Content>
        <Breadcrumb
          postType={`page`}
          postTitle={page.title ?? ''}
          postURI={page.uri ?? ''}
        />
        <h1 dangerouslySetInnerHTML={{ __html: page.title ?? '' }} />
        <hr />
        <span dangerouslySetInnerHTML={{ __html: page.content ?? '' }} />
      </Content>
      <Footer />
    </>
  )
}

export default PageTemplate
